@import '__importable.scss';
.banner {
	@include siteResponsiveContentContainer();
	display: flex;
	gap: 3.2rem;
	align-items: center;
	background: #84d0fc;
	min-height: unset;
	padding-top: 2.4rem !important;
	padding-bottom: 2.4rem !important;

	> svg {
		grid-row: span 3;
		align-self: start;
		width: 7.6rem;
		height: 7.6rem;
	}

	> div {
		width: 100%;
	}

	> a {
		span {
			font-size: 1.4rem;
			white-space: nowrap;
		}
	}

	@media screen and (max-width: $media-sm) {
		> a {
			padding: 9px 16px 10px;
		}
	}

	@media screen and (min-width: $media-sm) {
		grid-template-columns: min-content auto max-content;
		grid-template-rows: auto;

		> svg {
			grid-row: auto;
			align-self: center;
		}

		> a {
			span {
				font-size: inherit;
			}
		}
	}

	&.homePageReward {
		padding: 3.2rem 6.4rem;
		border-radius: $radius-4;
		display: flex;

		> svg {
			display: block !important;
		}

		@media screen and (max-width: $media-sm) {
			grid-template-columns: min-content auto;
			padding: 1.6rem;

			> svg {
				display: block;
			}
		}

		:global {
			// Widget Custom CSS
			.yotpo-widget-override-css {
				&.yotpo-widget-my-rewards-widget {
					text-align: left;

					.yotpo-logged-in-view {
						max-width: unset;
						padding: 0 !important;

						.yotpo-headline {
							margin: 0 0 2px 0;

							.yotpo-inner-text {
								font-size: 2rem !important;
								font-weight: 500 !important;
								line-height: 2.88rem !important;
							}
						}

						.yotpo-description {
							grid-row-start: 2;
							margin: 0 32px 0 0;

							.yotpo-inner-text {
								font-family: var(--poppins) !important;
								font-size: 1.4rem !important;
								font-weight: normal !important;
								line-height: 2.1rem !important;
							}
						}

						.yotpo-action-buttons-tile {
							display: none;
						}
					}
				}
			}
		}
	}

	&.isMyCollectionsTab {

		> svg {
			display: block !important;
		}


		@media screen and (max-width: $media-sm) {
			padding: 1.6rem;
			display: grid;
			grid-template-columns: auto auto;
			grid-template-rows: 1fr;
			grid-column-gap: 2rem;
			grid-row-gap: 0rem;
						

			> svg {
				display: block !important;
			}
		}

		:global {
			$minHeight: calc(102px - 1.6rem);

			// Widget Loading Behavior
			.yotpo-widget-instance {
				&[data-yotpo-instance-id='174304'] {
					@media screen and (max-width: $media-sm) {
						min-height: $minHeight;
						padding-bottom: 1.6rem;
					}
				}
			}

			// Widget Custom CSS
			.yotpo-widget-override-css {
				&.yotpo-widget-my-rewards-widget {
					text-align: left;

					@media screen and (max-width: $media-sm) {
						min-height: $minHeight;
						padding-bottom: 1.6rem;
					}

					.yotpo-rewards-guide-tile-wrapper {
						display: none !important;
					}

					.yotpo-logged-in-view {
						max-width: unset;
						padding: 0 !important;

						.yotpo-headline {
							margin: 0 0 2px 0;

							.yotpo-inner-text {
								font-size: 2.4rem !important;
								font-weight: 500 !important;
								line-height: 2.88rem !important;
							}
						}

						.yotpo-description {
							margin: 0 !important;

							.yotpo-inner-text {
								font-family: var(--poppins) !important;
								font-size: 1.4rem !important;
								font-weight: normal !important;
								line-height: 2.1rem !important;
							}
						}

						.yotpo-action-buttons-tile {
							display: none;
						}
					}
				}
			}
		}
		button {
			white-space: nowrap;
		}
	}

	&.isMyRewardsTab {
		grid-template-columns: auto;
		border-radius: 8px;
		padding: 1.6rem;

		> img {
			display: none;
		}

		@media screen and (min-width: $media-md-max) {
			grid-template-columns: min-content auto;
			padding: 1.6rem;

			> img {
				display: block;
			}
		}

		@media screen and (min-width: $media-xl) {
			padding: 3.2rem 6.4rem;
		}

		:global {
			$minHeight: 145px;

			// Widget Loading Behavior
			.yotpo-widget-instance {
				&[data-yotpo-instance-id='174298'] {
					@media screen and (max-width: $media-sm) {
						min-height: $minHeight;
					}
				}
			}

			// Widget Custom CSS
			.yotpo-widget-override-css {
				&.yotpo-widget-my-rewards-widget {
					width: 100%;
					text-align: left;

					@media screen and (max-width: $media-sm) {
						min-height: $minHeight;
					}

					:focus {
						box-shadow: none;
					}

					.yotpo-rewards-guide-tile-wrapper {
						display: none !important;
					}

					.yotpo-logged-in-view {
						display: grid;
						grid-template-columns: repeat(2, auto);
						grid-template-rows: repeat(2, auto);
						grid-column-gap: 3.2rem;
						max-width: unset;
						margin: 0;
						padding: 0;

						@media screen and (width < $media-md-max) {
							grid-template-columns: repeat(1, auto);
							grid-template-rows: repeat(1, auto);
						}

						.yotpo-headline {
							margin: 0 0 2px 0;

							.yotpo-inner-text {
								font-size: 2.4rem !important;
								font-weight: 500 !important;
								line-height: 2.88rem !important;
							}
						}

						.yotpo-description {
							grid-row-start: 2;
							margin: 0 32px 0 0;

							.yotpo-inner-text {
								font-family: var(--poppins) !important;
								font-size: 1.4rem !important;
								font-weight: normal !important;
								line-height: 2.1rem !important;
							}
						}

						.yotpo-action-buttons-tile {
							grid-row: span 2;
							justify-content: flex-end;

							.yotpo-primary-button-link {
								display: none;
							}

							.yotpo-secondary-button {
								padding: 0 !important;
							}

							.yotpo-action-button-widget {
								.yotpo-button-style {
									border-radius: 4px;

									// They made a typo, lol. Also adding correct spelling here in case they fix.
									.yopto-widget-button-text,
									.yotpo-widget-button-text {
										font-family: var(--poppins);
										font-size: 1.4rem !important;
										font-weight: normal !important;
										line-height: 2.1rem !important;
									}
								}
							}
						}
					}

					@mixin myActivityMobileStyling() {
						.yotpo-logged-in-view {
							.yotpo-headline {
								margin: 0 0 8px 0;
							}

							.yotpo-description {
								margin: 0;
							}

							.yotpo-action-buttons-tile {
								margin-top: 16px;
								justify-content: stretch;

								.yotpo-secondary-button {
									width: 100%;

									.yotpo-button-style {
										width: 100%;
									}
								}
							}
						}
					}

					.yotpo-is-mobile {
						@include myActivityMobileStyling();
					}

					@media screen and (max-width: $media-md) {
						@include myActivityMobileStyling();
					}
				}
			}
		}
	}
}

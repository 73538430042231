@import '__importable.scss';
.accountTab {
	margin-top: 0;
}

.list {
	position: sticky;
	top: $headerHeight;
	z-index: $zindex-2;
	background-color: $white;
	border-bottom: 1px solid $gray-2;
	@include padding-inline;

	padding-right: 0;
	overflow: visible;

	>div {
		max-width: $max-width;
		margin: auto;
	}
}

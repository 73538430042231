@import '__importable.scss';
.card {
	background-color: $gray-1;
	border: 1px solid $gray-2;
	border-radius: $radius-4;
	padding: 1.6rem;
	text-align: center;

	img {
		object-fit: contain;
		mix-blend-mode: multiply;
		height: fit-content;
	}
}

.disclaimer {
	a {
		color: $blue-tint;
		&:hover {
			opacity: 0.6;
		}
	}
}

.grid {
	margin-top: 1.2rem;
	display: grid;

	.products {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		place-items: center;
		gap: 0.8rem;

		@media screen and (min-width: $media-md-max) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: $media-lg) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.collection {
	padding: 1.6rem 0 0.8rem;

	@media screen and (width >= $media-md-max) {
		padding: 1.6rem 0;
	}

	@media screen and (width >= $media-xl) {
		padding: 3.2rem 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		padding: 3.2rem 6.4rem;
	}

	@media screen and (width >= $max-width) {
		padding: 3.2rem 12.8rem;
	}

	.collection-header {
		@media screen and (width <= $media-xl) {
			padding-left: 1.6rem;
		}
	}
}
